


import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Image } from 'components/atoms';
import Lunaticpop from 'components/Popups/Unchainpop/Lunaticpop';
// import Twisthumanpop from 'Components/Popups/Twist/Twisthumanpop'
import Unclepop from 'components/Popups/Unchainpop/Unclepop';
import Stunnerpop from 'components/Popups/Unchainpop/Stunnerpop';

// import Meseries from 'Components/Popups/Covaris/Meseries'
// import Mseries from 'Components/Popups/Covaris/Mseries'
// import Ultrasonic from 'Components/Popups/Covaris/Ultrasonic'
import Bigtunapop from 'components/Popups/Unchainpop/Bigtunapop';
import Bouncerpop from 'components/Popups/Unchainpop/Bouncerpop';
import Houndpop from 'components/Popups/Unchainpop/Houndpop';
import Hunkypop from 'components/Popups/Unchainpop/Hunkypop';
import Juniorpop from 'components/Popups/Unchainpop/Juniorpop';






const useStyles = makeStyles(theme => ({
  cardBase: {
    '&:hover': {
      background: "#7DEDFF",
      '& .card-icon, & .card-title': {
        color: 'white',
      },
    },
  },
  icon: {
    fontSize: 60,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: 80,
    },
  },
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

const Unchainedlab= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
         <CardBase >

<Image src="https://ik.imagekit.io/j6phbjneuop/New_Folder/Principal_Partner_Logos/unchained-labs-logo_KOTOsmtCW.png?updatedAt=1626937058275" />

</CardBase>
<br/>



      <SectionHeader
        title="Featured products"
        subtitle="Unchained lab
        "
        data-aos="fade-up"
      />

      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/premasweb/lunatic_k8-IxbJYm.png" />


            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3 align='center'>Lunatic</h3>&nbsp;
              {/* <Typography  variant="inherit" color="primary">system</Typography> */}
            </Typography>


            <br />
            <br />
            <br/>

            <Lunaticpop />


          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/unchained/stunner_LL9Um7PLx.png?updatedAt=1627715331517" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3 align='center'>Stunner </h3>&nbsp;
              {/* <Typography  variant="inherit" color="primary">system</Typography> */}
            </Typography>


            <br />
            <br />
            <br/>
            

         
   <Stunnerpop/>


          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/unchained/uncle_zlvJ5U-8B82.png?updatedAt=1627715332887" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3> Uncle </h3>&nbsp;
              <Typography align='center' variant="inherit" color="primary"></Typography>
            </Typography>

            <br />
            <br />
            <br />
     
        

            <Unclepop/>



          </CardBase>
        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/unchained/Bouncer_OXrGV_kB6nK.png?updatedAt=1627715329116" />


            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3 align= 'center'> Bouncer</h3>&nbsp;
              <Typography variant="inherit" color="primary"></Typography>
            </Typography>


            <br />
            <br/>
            <br/>
            

        
            <Bouncerpop/>

          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/unchained/Big_tuna_S4t8NxCEp.png?updatedAt=1627715328507" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3>Big Tuna</h3>&nbsp;
              <Typography variant="inherit" color="primary"> </Typography>
            </Typography>


<br/>
            <br />
            <br />



            <Bigtunapop/>

          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/PREMAS_OPTMIZED_CONTENT/lifescience_products/Hunky_ft8BN8iEXl64.png" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3>Hunky</h3>&nbsp;
              <Typography variant="inherit" color="primary"> </Typography>
            </Typography>


<br/>
            <br />
            <br />



            <Hunkypop/>

          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/PREMAS_OPTMIZED_CONTENT/lifescience_products/junior_Bao3bcS0GFEM.png" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3>Junior</h3>&nbsp;
              <Typography variant="inherit" color="primary"> </Typography>
            </Typography>


<br/>
            <br />
            <br />



            <Juniorpop/>

          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
        <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://ik.imagekit.io/j6phbjneuop/unchained/hound_vw26KnK56.png?updatedAt=1627715329994" />
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3> Hound</h3>&nbsp;
              <Typography variant="inherit" color="primary"> </Typography>
            </Typography>


<br/>
            <br />
            <br />



            <Houndpop/>

          </CardBase>
     

        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">

        </Grid>


      </Grid>
    </div>
  );
};

Unchainedlab.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Unchainedlab;
