import React from 'react'
import Simplewestern from './Simplewestern'
// import { IconAlternate } from 'components/molecules';


export const services = [
    {
      icon: 'fas fa-layer-group',
      title: 'multiple detection channels.',
      subtitle:
        'Automates traditional Western blotting while maximizing multiplexing with multiple detection channels. ',
    },
    {
      icon: 'fas fa-dollar-sign',
      title: 'Fast',
      subtitle:
        ' Separates your proteins by size, and precisely manages antibody additions, incubations, washes and even the detection steps. analysed western blotting results on 13 or 25 samples in 3 hours.',
    },
    {
      icon: 'fas fa-dna',
      title: 'Multiple Analysis',
      subtitle:
        "Gives you different ways to analyse protein - FLUORESCENCE DETECTION, CHEMILUMINESCENCE DETECTION, PROTEIN NORMALIZATION and BLOT IMAGING.",
    },
  
  ];

function Simplewesternpart() {
    return (
        <div>
           <Simplewestern data = {services}/> 
        </div>
    )
}

export default Simplewesternpart
