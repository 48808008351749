import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const NovocyteP= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={2} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
           Flow Cytometer Systems 5 Lasers 
                <br />
                <Typography component="span" variant="inherit" color="primary">
                NovoCyte Penteon 
                </Typography>
              </span>
            }
            subtitle= " The NovoCyte Penteon flow cytometer builds on its successful predecessor, the NovoCyte, and provides an expanded set of capabilities that accommodate today’s high-end and increasingly sophisticated multi-color flow cytometry assays. You now have the flexibility to choose from up to 30 fluorescence channels utilizing up to 5 lasers with up to 30 independent detectors. The NovoSampler Q can be integrated into different laboratory automation platforms and efficiently processes both FACS tubes (using a 40-tube rack) and 24-, 48-, 96-, and 384-well plates. The intuitive and industry leading NovoExpress software has been further advanced, providing an exceptional user experience in data acquisition, analysis and reporting."
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
           Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
        Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/Acea%20Biosciences/Panteon.png"
            alt="Panteon"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

NovocyteP.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default NovocyteP;
