import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Image } from 'components/atoms';
// import Alloseqdna from 'Components/Popups/Caredx/Alloseqdna'
import Cypher from 'components/Popups/Indevpop/Cypher'
// import Vaxpop from 'components/Popups/Indevpop/Vaxpop'
// import Olerupsbt from 'Components/Popups/Caredx/Olerupsbt'
// import Olerupssp from 'Components/Popups/Caredx/Olerupssp'
// import Olerupxmone from 'Components/Popups/Caredx/Olerupxmone'
// import Qtype from 'Components/Popups/Caredx/Qtype'
// import Trusight from 'Components/Popups/Caredx/Trusight'





const useStyles = makeStyles(theme => ({
  cardBase: {
    '&:hover': {
      background: '#7DEDFF',
      '& .card-icon, & .card-title': {
        color: 'white',
      },
    },
  },
  icon: {
    fontSize: 60,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: 80,
    },
  },
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },

}));

const Indevall = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>

<CardBase >

<Image src="https://onwhicar.sirv.com/premas/main/dna%20script/DNA-Script-Logo-med.png" />

</CardBase>

<br/>

      <SectionHeader
        title="Featured products"
        subtitle="DNA script"
        data-aos="fade-up"
      />


      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://onwhicar.sirv.com/premas/main/dna%20script/SYNTAX_from-right-min%5B2432%5D.png" />


            </div>

            <br />


            <Typography align ='center' variant="h6" component="span" className={classes.fontWeight900}>
              <h3>Syntax System </h3>  &nbsp;
              <Typography  component="span" variant="inherit" color="primary"></Typography>
            </Typography>


            <br />
            <br />
            <br />
            <Cypher />
      
          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
     
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          
        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          
        </Grid>
        
      
      
       
          
    

      </Grid>
    </div>
  );
};

Indevall.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Indevall;