import React from 'react'
import Ella from './Ella'
// import { IconAlternate } from 'components/molecules';


export const services = [
    {
      icon: 'fas fa-layer-group',
      title: 'Validation',
      subtitle:'Helps the user to get fast and highly reproducible validated assay data with no manual steps.'
        
    },
    {
      icon: 'fas fa-dollar-sign',
      title: 'Fast',
      subtitle:
        ' It offers single plex (upto 72X1 cartridge), multiplex & multi-analyte (16X4, 32X4) and customizable possibilities. A high level of sub-picogram sensitivity with 4+ logs of dynamic range and complete automation.',
    },
    {
      icon: 'fas fa-dna',
      title: 'Multiple Analysis',
      subtitle:
        "⦁	Everything is pre-loaded making your assay more accurate, error-free and convenient. ",
    },
  
  ];

function Ellapart() {
    return (
        <div>
           <Ella data = {services}/> 
        </div>
    )
}

export default Ellapart
