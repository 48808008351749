import React from 'react';
import {  Grid, Divider,} from '@material-ui/core';

import Codexdnall from 'components/Pcat/Codexdnall/Codexdnall';









export default function Codexfetch() {


  return (
    <div>
      <Codexdnall />







      <Grid item xs={12}>
        <Divider />
      </Grid>





    </div>

  );
}

