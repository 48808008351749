import React from 'react';






import {

  Grid,

  Divider,
} from '@material-ui/core';
import Askionall from '../../../../components/Pcat/Askionall/Askionall';










export default function Askionfetch() {


  return (
    <div>
      <Askionall />







      <Grid item xs={12}>
        <Divider />
      </Grid>





    </div>

  );
}

