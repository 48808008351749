import React from 'react';
import { createStyles, makeStyles,  } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Lunatic from '../../Pcat/Unchainedlab/Lunatic';

// import { TransitionProps } from '@material-ui/core/transitions';



const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',

    },
    colors:{
        color:theme.palette.secondary.main
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="" onClick={handleClickOpen}>
      Explore
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar >
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
           close
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
      Lunatic
            </Button>
          </Toolbar>
        </AppBar>
      
       <Lunatic/>
      
  
      </Dialog>
    </div>
  );
}