import React from 'react';






import {

  Grid,

  Divider,
} from '@material-ui/core';
import Indevall from 'components/Pcat/Indevall/Indevall';









export default function Indevfetch() {


  return (
    <div>
      <Indevall/>







      <Grid item xs={12}>
        <Divider />
      </Grid>





    </div>

  );
}

