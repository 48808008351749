import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    font:{
        fontSize:'16px'
    },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Stunner= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
             Hero Head
                <br />
                <Typography component="span" variant="inherit" color="primary">
       Stunner
                </Typography>
              </span>
            }
            subtitle="
            Stunner pulls double-duty so you don’t have to. It’s the only system out there that takes UV/Vis concentration and Dynamic Light Scattering (DLS) size measurements from the same sample at the same time. Nail down your protein quality by knocking concentration, hydrodynamic size, polydispersity, and detection of aggregates off your list in one shot. Without skipping a beat, you can also measure the colloidal stability of your samples or design your own custom apps.
            Helps you study-
            . 
            "
            align="left"
            disableGutter
            titleVariant="h3"

            />
            <ul className ={classes.font}>
                <li>
                Concentration 
                </li>

                <li>
            Sizing 

                </li>
                <li>
            Polydispersity 
                </li>
                <li>
                B22 

                </li>
                <li>
                kD 
                </li>

                <li>
            Homebrew
                </li>

              
            </ul>

            <SectionHeader
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
                Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
              Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://ik.imagekit.io/j6phbjneuop/unchained/stunner_LL9Um7PLx.png?updatedAt=1627715331517"
            alt="Stunner"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Stunner.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Stunner;
