import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    font:{
        fontSize:'16px'
    },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Xcell= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
           Real time cell analysis with
                <br />
                <Typography component="span" variant="inherit" color="primary">
 xCELLigence
                </Typography>
              </span>
            }
            subtitle="An innovative technology for Real Time Kinetic Measurements. Reduces assay time and expedite workflow. Real-time, label-free monitoring of cell health and behaviour. 96 wells in less than 15 seconds. It covers many measurement modalities, including energy metabolism, real-time cell viability, contractility, movement (impedance), and flow cytometry.
            "
            align="left"
            disableGutter
            titleVariant="h3"

            />
            Application areas-
· Quality control of cells (Proliferation and viability)
· Cell adhesion & Spreading
· Compound mediated cyto-toxicity/Apoptosis
· Cell Invasion & Migration

          
            <SectionHeader
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
                Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
              Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/Acea%20Biosciences/xcell.png"
            alt="Xcell"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Xcell.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Xcell;
