import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Image } from 'components/atoms';
import Coldpop from 'components/Popups/Askionpop/Coldpop';
import Workpop from 'components/Popups/Askionpop/Workpop';
import Hermpop from 'components/Popups/Askionpop/Hermpop';
// import Olerupsbt from 'Components/Popups/Caredx/Olerupsbt'
// import Olerupssp from 'Components/Popups/Caredx/Olerupssp'
// import Olerupxmone from 'Components/Popups/Caredx/Olerupxmone'

// import Trusight from 'Components/Popups/Caredx/Trusight'





const useStyles = makeStyles(theme => ({
  cardBase: {
    '&:hover': {
      background: '#7DEDFF',
      '& .card-icon, & .card-title': {
        color: 'white',
      },
    },
  },
  icon: {
    fontSize: 60,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: 80,
    },
  },
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },

}));

const Askionall = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
             <CardBase >

<Image src="https://onwhicar.sirv.com/premas/main/askion/askionsmall.png" />

</CardBase>

<br/>

      <SectionHeader
        title="Featured products"
        subtitle=" Askion "
        data-aos="fade-up"
      />


      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://onwhicar.sirv.com/premas/main/askion/clinehermitic-askion.png" />


            </div>

            <br />


            <Typography variant="h6" component="span" className={classes.fontWeight900}>
              <h3> C-line </h3>  &nbsp;
              <Typography component="span" variant="inherit" color="primary">Hermetic Storage</Typography>
            </Typography>



            <br />
            <br />

            <Hermpop />
          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://onwhicar.sirv.com/premas/main/askion/cline-coldeye.png" />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br/>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3> C-line</h3>  &nbsp;
              <Typography component="span" variant="inherit" color="primary">Workbench</Typography>
            </Typography>



            <br />
            <br />

            <Workpop />
            {/* <Typography
              variant="h5"
              color="textPrimary"
              align="center"
              noWrap
              // className={clsx(classes.title, 'card-title')}
            >
Single Cell
 Gene Expression 
            </Typography> */}


          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src="https://onwhicar.sirv.com/premas/main/askion/clineworkbench.png" />
            </div>
            <br/>
            <br/>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
              <h3> C-line </h3>  &nbsp;
              <Typography component="span" variant="inherit" color="primary"> ColdEye</Typography>
            </Typography>

            <br />
            <br />

            <Coldpop />
          </CardBase>
        </Grid>
      </Grid>
 
      
      
       
          
    

    
    </div>
  );
};

Askionall.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Askionall;
