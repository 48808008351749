import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({

    font:{
        fontSize:'18px'
    },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Bioxp = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
       Revolutionize Synthetic Biology with the power of World’s first fully automated gene Synthesizing platform 
                <br />
                <Typography component="span" variant="inherit" color="primary">
                BioXp ™ 3250
                </Typography>
              </span>
            }
          
            align="left"
            disableGutter
            titleVariant="h3"

            />
            <ul className = {classes.font}>
                <li>
                Builds up to 32 complex constructs from 300 bp to 7000 bp with multiple fragments and vectors overnight. 
                </li>

                <li>
        Bypass plasmid preps and synthesize transfection-ready DNA quantities of ~10 μg. 
	





                </li>
                <li>
                Eliminate cloning and synthesize de novo gene constructs with sequence modifications. 
                </li>
                <li>
                Streamlines clinical research and allows for distributed manufacturing of personalized therapies. 

                </li>
                <li>

                Automate, speed up and Optimize DNA design & synthesis and ensure your designs stay in your hands for innovation in drug discovery, vaccine development, genome editing, DNA data storage and beyond. 


                </li>

                <li>
           
	MRNA workflow also available

                </li>

                <li>
                Build better. Build faster. Build cheaper. Only on the BioXp™ 

                </li>

    
               
            </ul>

            <SectionHeader
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
                Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
              Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/bioxp/bioxp.png"
            alt="Bioxp"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Bioxp.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Bioxp;
