/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
 import React from 'react';
 import NoSsr from '@material-ui/core/NoSsr';

 import Main from 'layouts/Main';
 import WithLayout from 'WithLayout';
import Cellbiology from '../src/views/Cellbiology';
 
 const Component = () => {
     return (
         <NoSsr>
             <Cellbiology/>
         </NoSsr>
     );
 };
 
 const CellbiologyPage = () => {
     return (
         <WithLayout
             component={Component}
             layout={Main}
         />
     )
 };
 
 export default CellbiologyPage;
 