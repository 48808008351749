import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const NovocyteQ= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={2} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
      Flow Cytometer Systems 4 Lasers
                <br />
                <Typography component="span" variant="inherit" color="primary">
                NovoCyte Quanteon 
                </Typography>
              </span>
            }
            subtitle= " The NovoCyte Quanteon is a new generation in the successful line of NovoCyte flow cytometers. The Quanteon is a 4 laser flow cytometer that can be configured with up to 25 independent photomultipliers for meeting the most demanding sample panels. The proprietary photomultiplier technology employed in the Quanteon provides excellent sensitivity, stability, and a 7.2 log dynamic range. Very dim signals and very bright signals can all be captured and gated in the same view, therefore eliminating the need for laborious trial and error PMT tuning procedures.When developing large color panels, the Quanteon saves hours of setup and analysis time. Excellent fluidics provide steady and consistent sample delivery, with high reproducibility and exceptionally low CVs. Absolute counts, without the use of expensive beads, will save you time and money."
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
           Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
              Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/Acea%20Biosciences/Quanteon-Forward-web-2.png"
            alt="Quanteon"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

NovocyteQ.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default NovocyteQ;

