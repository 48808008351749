import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Cypher= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={5} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
Welcome to the bio-revolution

                <br />
                <Typography component="span" variant="inherit" color="primary">
                SYNTAX System
                </Typography>
              </span>
            }
            subtitle= "Introducing a new paradigm in oligo synthesis, the SYNTAX System, powered by Enzymatic DNA Synthesis (EDS) technology to accelerate life science innovation, from DNA Script. Designed for ease-of-use, productivity, convenience, control, and confidentiality, the SYNTAX System enables automated nucleic acid synthesis within a benchtop solution. Researchers can now have same-day synthesis of custom oligos at the lab bench, without the need for specialized infrastructure or technical skills, and increased control over their workflows."
            align="left"
            disableGutter
            titleVariant="h3"
          />
<ul>
Fully automated, walk-away synthesis 
Plug-and-play integration 
Parallel synthesis in 96-well plates 
Up to 96 oligos per run 
15-minute setup time per run 
same-day synthesis of 15 - 30 nt oligos enables two runs in 24 hours
Synthesize oligos overnight for nextday use
15 - 60 nt de novo oligo synthesis (A, C, G and T)
Custom iDNA length 15 - 40 nt
Ready-to-use oligos for molecular biology and genomics applications 
Default 5’-phosphate 
200 - 300 pmol per well
Normalized to 4 - 7 µM

</ul>

         <SectionHeader 
           ctaGroup={[
            <Button variant="contained" color="primary" size="large">
              Quickbook
            </Button>,
            <Button variant="outlined" color="primary" size="large">
              Talk to us
            </Button>,
          ]}
          align="left"
          disableGutter
          titleVariant="h3"
        />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/dna%20script/SYNTAX_from-right-min%5B2432%5D.png"
            alt="Cypher"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Cypher.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Cypher;
