import React from 'react';
import Protiensimpleall from 'components/Pcat/Protiensimpleall/Protiensimpleall'





import {

  Grid,

  Divider,
} from '@material-ui/core';









export default function Protiensimple() {


  return (
    <div>
      <Protiensimpleall />







      <Grid item xs={12}>
        <Divider />
      </Grid>





    </div>

  );
}

