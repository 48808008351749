import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Bouncer= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
             Hero Head
                <br />
                <Typography component="span" variant="inherit" color="primary">
         Bouncer
                </Typography>
              </span>
            }
            subtitle="Wonky silicone coatings for injectable devices can jam up or 
            ooze oil into your drug. Bouncer makes it easy to find 
            out if the coating’s too thick, too thin or good to go.
             Its quantitative measurements on silicone layer thickness
             and distribution let you find the sweet spot for your device.
            . . 
            "
            align="left"
            disableGutter
            titleVariant="h3"
            ctaGroup={[
                <Button variant="contained" color="primary" size="large">
                  Quickbook
                </Button>,
                <Button variant="outlined" color="primary" size="large">
                Talk to us
                </Button>,
              ]}

            />
     
                

        
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://ik.imagekit.io/j6phbjneuop/unchained/Bouncer_OXrGV_kB6nK.png?updatedAt=1627715329116"
            alt="Bouncer"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Bouncer.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Bouncer;
