import React from 'react';
import clsx from 'clsx';
import { parse } from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, ListItem, Grid, Typography } from '@material-ui/core';
import { SectionAlternate, CardBase } from 'components/organisms';
import {  Codexfetch, Unchainfetch,Protiensimple ,Indevfetch, Alsfetch ,Askionfetch , Genscriptfetch} from 'views/Cellbiology/components';
import {Hero} from 'views/Cellbiology/components';
import { Fluidigmfetch } from 'views/Genomic/components';



const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    section: {
        '& .section-alternate__content': {
            paddingTop: 0,
            marginTop: theme.spacing(-5),
            position: 'relative',
            zIndex: 1,
        },
        '& .card-base__content': {
            padding: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
    },
    menu: {
        height: 'auto',
    },
    list: {
        display: 'inline-flex',
        overflow: 'auto',
        flexWrap: 'nowrap',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: theme.spacing(-3),
            marginLeft: theme.spacing(-3),
        },
    },
    listItem: {
        marginRight: theme.spacing(2),
        flex: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            borderLeft: '2px solid transparent',
        },
    },
    listItemActive: {
        [theme.breakpoints.up('md')]: {
            borderLeft: `2px solid ${theme.palette.primary.dark}`,
        },
        '& .menu__item': {
            color: theme.palette.text.primary,
        },
    },
}));

const subPages = [


    // {
    //     id: 'unchained',
    //     href: '/Cellbiology/?pid=unchained',
    //     title: 'Unchained Labs',
    // },

    {
        id: 'fluidigm',
        href: '/Cellbiology/?pid=fluidigm',
        title: 'Fluidigm',
    },
    {
        id: 'ps',
        href: '/Cellbiology/?pid=ps',
        title: 'Protein Simple',
    },

    {
        id: 'codex',
        href: '/Cellbiology/?pid=codex',
        title: 'Codex DNA',
    },
    {
        id: 'askion',
        href: '/Cellbiology/?pid=askion',
        title: 'Askion',
    },
    {
        id: 'acea',
        href: '/Cellbiology/?pid=acea',
        title: 'Agilent',
    },
    {
        id: 'genscript',
        href: '/Cellbiology/?pid=genscript',
        title: 'GenScript',
    },
    {
        id: 'indev',
        href: '/Cellbiology/?pid=indev',
        title: 'Indev',
    },


];

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Box component="div" hidden={value !== index} {...other}>
            {value === index && children}

        </Box>
    );
};

const Cellbiology = (props = {}) => {
    const classes = useStyles();
    let pageId = parse(window.location.search).pid || 'unchained';


    return (
        <div className={classes.root}>
            <Hero />
            <SectionAlternate className={classes.section}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <CardBase withShadow align="left" className={classes.menu}>
                            <List disablePadding className={classes.list}>
                                {subPages.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        component={'a'}
                                        href={item.href}
                                        className={clsx(
                                            classes.listItem,
                                            pageId === item.id ? classes.listItemActive : {},
                                        )}
                                        disableGutters
                                    >

                                        <Typography
                                            variant="subtitle1"
                                            noWrap
                                            color="textSecondary"
                                            className="menu__item"
                                        >
                                            {item.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </CardBase>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <CardBase withShadow align="left">
                            {/* <TabPanel value={pageId} index={'illuminia'}>


                                <Illuminiafetch />


                            </TabPanel>
                            <TabPanel value={pageId} index={'genomicrange'}>
                                <Genomicfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'fluidigm'}>
                                <Fluidigmfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'covaris'}>
                                <Covarisfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'caredx'}>
                                <Caredxfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'genolution'}>
                                <Genolutionfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'isohelix'}>
                                <Isohelixfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'jnmedsys'}>
                                <Jnmedsysfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'twist'}>
                                <Twistfetch />
                            </TabPanel> */}
                            <TabPanel value={pageId} index={'unchained'}>
                                <Unchainfetch />
                            </TabPanel>
                  
                            <TabPanel value={pageId} index={'codex'}>
                                <Codexfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'ps'}>
                                <Protiensimple />
                            </TabPanel>
                            <TabPanel value={pageId} index={'fluidigm'}>
                                <Fluidigmfetch/>
                            </TabPanel>
                            <TabPanel value={pageId} index={'askion'}>
                                <Askionfetch/>
                            </TabPanel>
                            <TabPanel value={pageId} index={'acea'}>
                                <Alsfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'genscript'}>
                                <Genscriptfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'indev'}>
                                <Indevfetch />
                            </TabPanel>
                        </CardBase>
                    </Grid>
                </Grid>
            </SectionAlternate>
        </div>
    );
};

export default Cellbiology;
