import React from 'react';






import {

  Grid,

  Divider,
} from '@material-ui/core';
import Genscriptall from '../../../../components/Pcat/Genscriptall/Genscriptall';









export default function Genscriptfetch() {


  return (
    <div>
      <Genscriptall />







      <Grid item xs={12}>
        <Divider />
      </Grid>





    </div>

  );
}

