


import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography,  } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Image } from 'components/atoms';
// import Iscan from 'Popups/Illuminia/Iscan'
// import Iseq from 'Popups/Illuminia/Iseq'
// import Miniseq from 'Popups/Illuminia/Miniseq'
// import Miseq from 'Popups/Illuminia/Miseq'
// import Nextse from 'Popups/Illuminia/Nextse'
// import Nextseq from 'Popups/Illuminia/Nextseq'
// import Novaseq from 'Popups/Illuminia/Novaseq'
import Swpop from 'components/Popups/Pspop/Swpop';
import Ellapop from 'components/Popups/Pspop/Ellapop';
import Milopop from 'components/Popups/Pspop/Milopop';
import Icepop from 'components/Popups/Pspop/Icepop';





const useStyles = makeStyles(theme => ({
  cardBase: {
    '&:hover': {
      background: "#7DEDFF",
      '& .card-icon, & .card-title': {
        color: 'white',
      },
    },
  },
  icon: {
    fontSize: 60,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: 80,
    },
  },
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

const Protiensimpleall = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
                   <CardBase >

<Image src="https://onwhicar.sirv.com/premas/main/ps/ps.png"/>

</CardBase>
       <br/>        
       
      <SectionHeader
        title="Featured products"
        subtitle="Protein simple"
        data-aos="fade-up"
      />

      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src = "https://onwhicar.sirv.com/premas/main/ps/ps-simplewestern.png"/>

           
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
     <h3 align= 'center'>   Simple Westerns </h3>&nbsp;

    </Typography>


    <br/>
<br/>
       
        <Swpop/>


          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
            <Image src = "https://onwhicar.sirv.com/premas/main/ps/ps-ice.png"/>
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
     <h3>ICE systems </h3>&nbsp;
      
    </Typography>


    <br/>
<br/>
       
        <Icepop/>  
  


          </CardBase>
        </Grid>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
            <Image src = "https://onwhicar.sirv.com/premas/main/ps/simpleplex.png"/>
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
     <h3 align= 'center'>Ella  </h3>&nbsp;
      {/* <Typography  variant="inherit" color="primary">550</Typography> */}
    </Typography>

    <br/>
<br/>

<Ellapop/>  
      



          </CardBase>
        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4} data-aos="fade-up">
          <CardBase withShadow liftUp className={classes.cardBase}>
            <div>
              <Image src = "https://onwhicar.sirv.com/premas/main/ps/singlecellwestern.png"/>

           
            </div>
            <Typography variant="h5" component="span" className={classes.fontWeight900}>
     <h3> Milo</h3>&nbsp;
      {/* <Typography  variant="inherit" color="primary">2000</Typography> */}
    </Typography>


    <br/>
<br/>
  
<Milopop/>

          </CardBase>
        </Grid>
       
     
      </Grid>

    </div>
  );
};

Protiensimpleall.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Protiensimpleall;
