import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Junior= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
             Hero Head
                <br />
                <Typography component="span" variant="inherit" color="primary">
Junior
                </Typography>
              </span>
            }
            subtitle="Junior automates all your hands-on, back and forth and hard to do biologics formulation tasks. It ties them together and makes them one totally routine, high throughput process. Hand off the grunt work to Junior and hit the next big thing on your plate instead.
           
            . 



            "
            align="left"
            disableGutter
            titleVariant="h3"

            />
   
   Helps you study- ⦁	Viscosity 
⦁	pH measurement 
⦁	Visual Inspection

            <SectionHeader
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
                Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
              Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://ik.imagekit.io/j6phbjneuop/PREMAS_OPTMIZED_CONTENT/lifescience_products/junior_Bao3bcS0GFEM.png"
            alt="Junior"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Junior.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Junior;
