import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const NovocyteA= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={2} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
          Flow Cytometer Systems 1-3 Lasers
                <br />
                <Typography component="span" variant="inherit" color="primary">
              Novacyte Advanteon
                </Typography>
              </span>
            }
            subtitle= " The NovoCyte Advanteon flow cytometer builds upon the highly successful NovoCyte, and NovoCyte Quanteon. The system provides an advanced set of capabilities for the most demanding scientists, yet is remarkably simple to operate. The NovoCyte Advanteon can accommodate high-end and increasingly sophisticated multicolor flow cytometry assays. The system offers flexibility with 1, 2, or 3 laser options, up to 21 fluorescence channels, and 23 independent detectors.The NovoCyte Advanteon is customizable to meet your specific needs but is easily upgradeable to meet your future demands. When throughput is essential, the NovoSampler Q can be integrated into different laboratory automation platforms. The sampler can efficiently process both FACS tubes (using a 40-tube rack) and 24-, 48-, 96-, and 384-well plates. For the NovoCyte Advanteon, the intuitive and industry honored NovoExpress software is now even more advanced, providing an exceptional user experience in data acquisition, analysis, and reporting. "
            ctaGroup={[
              <Button variant="contained" color="primary" size="large">
               Quickbook
              </Button>,
              <Button variant="outlined" color="primary" size="large">
            Talk to us
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/Acea%20Biosciences/NovoCyte_NovoSampler.png"
            alt="Adventeon"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

NovocyteA.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default NovocyteA;
