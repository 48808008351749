import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import {Box} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    font:{
        fontSize:'16px'
    },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Cpass= props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box  className={className} {...rest} marginBottom={2} marginLeft= {2} marginRight={2} marginTop= {2} display="flex" justifyContent="space-evenly" alignItems="center" padding={6} border="1px solid #ccc" borderRadius="4px">
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
           SARS-CoV-2 Neutralization Antibody Detection Kit 
                <br />
                <Typography component="span" variant="inherit" color="primary">
                cPass™ 
                </Typography>
              </span>
            }
            subtitle="cPass Neutralization Antibody Detection kit detects circulating neutralizing antibodies against SARS-CoV-2 that block the interaction between the receptor binding domain (RBD) of the viral spike glycoprotein with the ACE2 cell surface receptor. This kit would be instrumental in vaccine and therapeutic development as it is suitable for all antibody isotypes and can be used to determine neutralizing antibodies in animal models without modification. The kit will also help in current COVID-19 investigations of sero-prevalence, assessment of herd immunity, longevity of protective immunity, efficacy of different vaccine candidates as well as tracking infection in animals..
            "
            align="left"
            disableGutter
            titleVariant="h3"

            />


          
            <SectionHeader
          
            align="left"
            disableGutter
            titleVariant="h3"
          />


<ul>
<li> The cPass™ Neutralization Antibody Detection Kit can be rapidly conducted within an hour in most labs, and is also amenable to high throughput and fully automated testing.</li>
<li> Highly specific and sensitive and at the same time isotype- and species-independent.</li>
 	
<li> 96 well ELISA format test with ACE2 protein attached to the plate and HRP labeled RBD is used for detection.</li>	
<li> Can be used with serum and plasma samples.</li>	
<li> The kit comes with positive and negative controls.</li>
</ul>

<SectionHeader
 ctaGroup={[
    <Button variant="contained" color="primary" size="large">
      Quickbook
    </Button>,
    <Button variant="outlined" color="primary" size="large">
    Talk to us
    </Button>,
  ]}
  align="left"
  disableGutter
  titleVariant="h3"


/>


        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://onwhicar.sirv.com/premas/main/Genscript/saars-genscript.png"
            alt="Cpass"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Cpass.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Cpass;
